import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`La trésorerie facile`}</h1>
    <hr></hr>
    <h2>{`Bénéfices`}</h2>
    <ul>
      <li parentName="ul">{`Voir les difficultés arriver de loin pour avoir le temps `}</li>
      <li parentName="ul">{`Faire face immédiatement aux surprises toujours possibles`}</li>
      <li parentName="ul">{`Essayer les différentes solutions possibles et choisir la meilleure`}</li>
      <li parentName="ul">{`Ne rater aucune opportunité`}</li>
      <li parentName="ul">{`Avoir et donner confiance`}</li>
      <li parentName="ul">{`Dormir tranquille`}</li>
    </ul>
    <h2>{`Et si...`}</h2>
    <ul>
      <li parentName="ul">{`S'il était possible de `}<strong parentName="li">{`voir`}</strong>{` le solde de trésorerie prévu dans les prochains jours ou dans les prochains mois ?`}</li>
      <li parentName="ul">{`Si cette image était mise à jour au fil des factures payées ou non payées ?`}</li>
      <li parentName="ul">{`S'il était facile de `}<strong parentName="li">{`déplacer`}</strong>{` le règlement d'une facture (en entrée ou en sortie) dans le temps pour simuler les conséquences d'un imprévu ou à l’inverse d’un décalage volontaire ?`}</li>
      <li parentName="ul">{`S'il était simple d'essayer les différents scénarios de paiement qui permettent de maintenir la trésorerie positive pour choisir le plus rentable ou le moins risqué ?`}</li>
    </ul>
    <hr></hr>
    <h1>{`re-prendre le contrôle de sa trésorerie`}</h1>
    <h2>{`La carte du champs de bataille`}</h2>
    <p>{`La table de `}<strong parentName="p">{`toutes`}</strong>{` les factures: celles émises aux clients, celles reçues des fournisseurs et organismes, plus toutes celles qui sont prévisibles, déjà payées ou pas. `}<strong parentName="p">{`L’exhaustivité`}</strong>{` et la `}<strong parentName="p">{`justesse`}</strong>{` de cette table sont absolument cruciales: Il ne faut `}<strong parentName="p">{`JAMAIS`}</strong>{` être surpris et regarder en avant le plus loin possible.`}</p>
    <p>{`Cela suppose un entretien `}<strong parentName="p">{`rigoureux`}</strong>{`, pas forcément très long ou difficile: Deux choses à faire `}<strong parentName="p">{`une fois par semaine`}</strong>{`, ou moins ou plus selon les cycles de l’activité et à certainement à chaque surprise:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ajouter`}</strong>{` tous les nouveaux paiements connus et prévisibles: salaires, fournisseurs et clients.`}</li>
      <li parentName="ul"><strong parentName="li">{`pointer/supprimer`}</strong>{`  les paiements déjà réalisés (payés ou encaissés) à l’aide du relevé bancaire le plus récent. `}</li>
    </ul>
    <p>{`Cette table peut être: `}</p>
    <ul>
      <li parentName="ul">{`entretenue à l’aide de Captain Treso `}</li>
      <li parentName="ul">{`le résultat d’un export d’un outil de gestion ou de comptabilité`}</li>
      <li parentName="ul">{`un simple fichier de tableur entretenu à la main`}</li>
    </ul>
    <h2>{`Le futur : quand les factures seront-elles payées ?`}</h2>
    <p>{`Les factures `}<strong parentName="p">{`non encore payées`}</strong>{` et `}<strong parentName="p">{`le solde bancaire`}</strong>{` à un instant donné permettent de simuler les différents scénarios de paiement. Extraire (ou filtrer) de la table de toutes les factures, un fichier à 3 colonnes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Date`}</strong>{` au format jj/mm/aaaa, date prévue de paiement`}</li>
      <li parentName="ul"><strong parentName="li">{`Description`}</strong>{` (texte libre)`}</li>
      <li parentName="ul"><strong parentName="li">{`Montant`}</strong>{` (positif ou négatif)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "61.34969325153374%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAezOwKH/xAAXEAADAQAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAEFAhNzDJ//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAQAGPwJr/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARMSEQcf/aAAgBAQABPyG2+8n1Q6FkBWbAGHn/2gAMAwEAAgADAAAAEFMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAQEBAAMAAAAAAAAAAAAAAREAIRBRYf/aAAgBAQABPxAQFQU1PKvSXGqCnd2oo79zVAfH/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Table",
          "title": "Table",
          "src": "/static/19c170007fb32d44cf1b08577e21bcde/6aca1/Table.jpg",
          "srcSet": ["/static/19c170007fb32d44cf1b08577e21bcde/d2f63/Table.jpg 163w", "/static/19c170007fb32d44cf1b08577e21bcde/c989d/Table.jpg 325w", "/static/19c170007fb32d44cf1b08577e21bcde/6aca1/Table.jpg 650w", "/static/19c170007fb32d44cf1b08577e21bcde/ad059/Table.jpg 758w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><em parentName="p">{`Les éventuelles colonnes supplémentaires sont simplement ignorées. Inutile de créer spécialement un fichier « à importer » il suffit d’avoir ces trois colonnes dans le fichier de référence qui peut contenir autant d’autres données que nécessaires: date d’émission, référence, TVA, commentaires, etc...`}</em>{` `}</p>
    <p>{`Le fichier est interprété relativement à la date du jour (il est possible de modifier cette date du jour en avant ou arrière au moment de l’importation).
La date du jour est très importante car toutes les lignes du fichier avec une date antérieure sont considérés comme  `}<strong parentName="p">{`impayées et en retard`}</strong>{` et rentrent automatiquement dans une zone grise ne faisant plus partie du prévisionnel automatique.`}</p>
    <p><em parentName="p">{`Cliquer ici pour télécharger un fichier d’exemple:`}</em>{` `}<a parentName="p" {...{
        "href": "/sandbox/tresorerie/frdata2.xlsx"
      }}>{`excel`}</a>{` ou `}<a parentName="p" {...{
        "href": "/sandbox/tresorerie/frdata2.csv"
      }}>{`csv`}</a></p>
    <p><em parentName="p">{`Cliquer ’TERMINER’ permet également d’obtenir l’équivalent xlsx de l’exemple par défaut.`}</em></p>
    <h2>{`Import des données`}</h2>
    <p>{`Le fichier (xlsx, xls ou csv) peut être désigné en cliquant ‘FICHIER’ ou glissé/déposé dans la fenêtre pour voir les graphiques se mettre en place et commencer à travailler.`}</p>
    <center>
      <p><img parentName="p" {...{
          "src": "/gifs/loopingdragdrop.gif",
          "alt": null
        }}></img></p>
    </center>
    <h2>{`L’interface`}</h2>
    <p>{`L’affichage est en plusieurs parties:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "60.73619631901841%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACo0lEQVQoz1VSy04UQRSdv0BZwN4/cONCf8Of4R+UjQtiFI2SYBwXkAySsDCBgZCMzIwCYR7NTPf0TE+/q6vrebzdiI9Kbu6tqq5zzz19GkvPW0+AlWhr62m4ufkEwAOj1DrlNU2Bv/GQ7X56nG1vP6N6BUKs03drClhdbGw8D9snj4ZhuNrQShVCKWbKUirfl1JrpoUoLGMFiqLOpqoBprJUq7lvqc6N1vUZsozLLIuVMTnnRdHI8xxGGyyWSySeB1gLwznK0QjBxQXMZEIxhUf7MAwxG80QLBeoUCElkvG4qqCqOknRmEwmNs+ZHQ2HdkAhhLDG0iLoII5tnOeUE9sdnNvTmyMbRLGVUtownNvMGVuWZbYshS0Yt1duxzZmsxlYUcB1XTiOg7IscQdnsVwGSKnr5bCHg95HtLo7WEZBzWjodBAvXFBzxGkELSx++CdoeDRmJRExxXQ6hdYaxpgadLFYoOQC3Ztz7HXfY//7DiaBXwNeeh1ELKGpDVpXbzCY/8SZc4A7hozVgPP5vAa6B6w047zE9U0Hve5ntM92Mem3IAffMLo+RtT/inhwjv60jeH1HgZ+hwB9vwasxnU9l9wg/gCmSYKC9k6/h+HrFxjtvoP34RWivS0IKZD5JFEeQTIJ/+1LoOD3DO80nC/mUEr9x7AgTcmrmLZacA8PEY6nEDknQAVeSpjKO0mM2dERnWe1hprspkk/PR6PNdlIE1gdQRBoqZTWnGsSX0u6U4zuAcN83wh6R811SeeyLE2ZJLqRplktcpamiKIY/65q/GqRiVGQX6tI4xhxECC8vUXOcpCXEUcR6U/S0X0jSZImMWzSeM0kTZs0cpOAmuS1Zvp7T3++zpzzL7mUu9xxjtN+v13VRPFz4vv78elpT9CbX9bacmgtXqNLAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "TresoImage",
          "title": "TresoImage",
          "src": "/static/a4fe864a68a42de8caeeac4bb7de0c51/a6d36/TresoImage.png",
          "srcSet": ["/static/a4fe864a68a42de8caeeac4bb7de0c51/222b7/TresoImage.png 163w", "/static/a4fe864a68a42de8caeeac4bb7de0c51/ff46a/TresoImage.png 325w", "/static/a4fe864a68a42de8caeeac4bb7de0c51/a6d36/TresoImage.png 650w", "/static/a4fe864a68a42de8caeeac4bb7de0c51/e548f/TresoImage.png 975w", "/static/a4fe864a68a42de8caeeac4bb7de0c51/62a6a/TresoImage.png 1122w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`la table de toutes les factures (image du fichier importé) qu’il est possible de trier par date, montant ou description`}</li>
      <li parentName="ol">{`le graphe du solde prévisionnel du compte calculé à partir des factures et du solde initial. Le solde positif est vert, le négatif est rouge ou orange si compris dans l’autorisation de découvert. `}</li>
      <li parentName="ol">{`la date du jour représentée par une ligne bleue verticale. La gauche de cette ligne est grisée.`}</li>
      <li parentName="ol">{`les factures à payer ou à recevoir groupées par jour. La couleur rouge indique une dépense, la couleur grise une rentrée`}</li>
      <li parentName="ol">{`la glissière de la période affichée que l’on peut déplacer, agrandir ou réduire.`}</li>
    </ol>
    <h2>{`Consulter pour comprendre`}</h2>
    <p>{`Promener le curseur sur les différentes représentations pour comprendre la position relative et l’influence de chaque facture sur le solde de la trésorerie.`}</p>
    <h2>{`Déplacer pour simuler`}</h2>
    <p>{`Chaque facture peut être `}<strong parentName="p">{`déplacée`}</strong>{` à la souris, sous sa forme de ligne dans la table ou sous sa forme de barre verticale. La courbe du solde est continûment mise à jour pour `}<strong parentName="p">{`voir`}</strong>{` les effets de ces modifications.  `}</p>
    <center>
      <p><img parentName="p" {...{
          "src": "/gifs/loopingsample.gif",
          "alt": null
        }}></img></p>
    </center>
    <h2>{`La date du jour`}</h2>
    <p>{`Toutes les factures à gauche de la ligne du jour sont les factures `}<strong parentName="p">{`en retard`}</strong>{`, qui auraient dues être payées mais qui ne l’ont pas été. Elles seront peut être régularisées dans un jour ou deux mais peut être jamais. A gauche d’aujourd’hui se trouvent toutes les factures devenues:`}</p>
    <ul>
      <li parentName="ul">{`« douteuses »: client en retard de règlement`}</li>
      <li parentName="ul">{`« brulantes »: fournisseurs toujours pas payés`}</li>
    </ul>
    <p>{`Dans ce « no man’s land » Ces factures ne `}<strong parentName="p">{`participent plus`}</strong>{` au calcul de l’évolution prévisionnelle du solde. Ne surtout pas les oublier pour autant. Il faut les faire passer à droite du jour à la main (leur affecter une date d’échéance future) pour les ré-intégrer dans le prévisionnel.`}</p>
    <p>{`Le passage d’une facture à gauche ou à droite de la ligne du jour permet de bâtir le prévisionnel `}<em parentName="p">{`plancher`}</em>{` de trésorerie uniquement sur les factures jugées suffisamment fiables tout en n’oubliant aucune des factures même devenues douteuses.`}</p>
    <h2>{`TERMINER: La synthèse de simulation`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "23.926380368098158%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3KAH/8QAFRABAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQEAAQUCj//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABgQAAIDAAAAAAAAAAAAAAAAAAARMWGR/9oACAEBAAE/IVvSBn//2gAMAwEAAgADAAAAEPAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAEFAAAAAAAAAAAAAAAAAQARIWFxkf/aAAgBAQABPxDJ0i0Aal0//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "export",
          "title": "export",
          "src": "/static/1dd90cec04cc54f4c173afd4846ab5ec/6aca1/export.jpg",
          "srcSet": ["/static/1dd90cec04cc54f4c173afd4846ab5ec/d2f63/export.jpg 163w", "/static/1dd90cec04cc54f4c173afd4846ab5ec/c989d/export.jpg 325w", "/static/1dd90cec04cc54f4c173afd4846ab5ec/6aca1/export.jpg 650w", "/static/1dd90cec04cc54f4c173afd4846ab5ec/1a144/export.jpg 730w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`A tout moment, cliquer ’`}<strong parentName="p">{`TERMINER`}</strong>{`’ permet d’exporter la synthèse des déplacements de factures au format xlsx:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Avant`}</strong>{`:       la date d’origine `}</li>
      <li parentName="ul"><strong parentName="li">{`Description`}</strong>{`: la description d’origine`}</li>
      <li parentName="ul"><strong parentName="li">{`Montant`}</strong>{`:     le montant d’origine`}</li>
      <li parentName="ul"><strong parentName="li">{`Après`}</strong>{`:       la date après simulation`}</li>
      <li parentName="ul"><strong parentName="li">{`Ecart`}</strong>{`:       le décalage de date avant/après en nombre de jours `}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.447852760736193%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFxABAAMAAAAAAAAAAAAAAAAAAQAQEf/aAAgBAQABBQLGJX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAVEAEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAQAGPwKv/8QAGBABAQADAAAAAAAAAAAAAAAAAQARIUH/2gAIAQEAAT8hFIudMjxv/9oADAMBAAIAAwAAABADz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQEBAQEAAAAAAAAAAAAAAAEhABEx/9oACAEBAAE/ECjxb7mh5vKro4S3f//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "ecarts",
          "title": "ecarts",
          "src": "/static/a06541e6024726fcfc7bbc2d76d57c73/6aca1/ecarts.jpg",
          "srcSet": ["/static/a06541e6024726fcfc7bbc2d76d57c73/d2f63/ecarts.jpg 163w", "/static/a06541e6024726fcfc7bbc2d76d57c73/c989d/ecarts.jpg 325w", "/static/a06541e6024726fcfc7bbc2d76d57c73/6aca1/ecarts.jpg 650w", "/static/a06541e6024726fcfc7bbc2d76d57c73/c2601/ecarts.jpg 832w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`L’ordre des lignes est `}<strong parentName="p">{`identique`}</strong>{` à celui du fichier d’entrée. Ce qui permet de localiser facilement les changements et les non-changements pour par exemple les re-importer dans un autre outil.
Il faut bien sûr trier le fichier selon les dates ‘Après’ pour obtenir la nouvelle chronologie des paiements.`}</p>
    <h2>{`Vos données sont en parfaite sécurité`}</h2>
    <p>{`Tous les traitements sont effectués localement à la web-app. Vos données ne sont transmises à aucun serveur distant, elles ne quittent pas votre navigateur. `}</p>
    <p>{` `}<strong parentName="p">{`Merci d'essayer cette `}<a parentName="strong" {...{
          "href": "https://alainro.github.io/cashflow"
        }}>{`application`}</a>{` de démonstration et de nous dire ce que vous en pensez.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      